import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

// When using WAF, handle waf-protected calls with
if (process.env.REACT_APP_AWS_WAF_INTEGRATION_USE === 'true') {

    // Request interceptor to handle protected routes
    instance.interceptors.request.use(async (config) => {
        // if the request is marked to be for a waf-protected endpoint, then configure the request accordingly.
        if (config.wafProtected === true) {
            try {
                const token = await window.AwsWafIntegration.getToken();

                config.headers['x-aws-waf-token'] = token;
            } catch (error) {
                console.error('Failed to get AWS WAF token or fetch request failed', error);
                throw error;
            }
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    });
}

export default instance;
