import React, {useEffect, useRef} from "react";

const WafCaptcha = ({ show, onVerified, locale = "de-DE" }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (show && containerRef.current) {
            // Ensure the CAPTCHA is only rendered if it isn't already initialized
            if (window.AwsWafCaptcha) {
                window.AwsWafCaptcha.renderCaptcha(containerRef.current, {
                    apiKey: process.env.REACT_APP_AWS_WAF_CAPTCHA_KEY,
                    onSuccess: onVerified,
                    dynamicWidth: true,
                    defaultLocale: locale,
                });
            } else {
                console.error("AWS WAF CAPTCHA library is not loaded.");
            }
        }
    }, [show, onVerified, locale]);

    return <div ref={containerRef} className={`w-100 ${show ? "" : "d-none"}`} />
}

export default WafCaptcha;