import React, {Component} from 'react';
import Fingerprint2 from 'fingerprintjs2';
import {Button, Col} from 'reactstrap';
import Modal from "../../components/UI/Modal/Modal";
import WafCaptcha from "../../components/WafCaptcha/WafCaptcha";
import axios from "../../axios-base";

class FingerprintVote extends Component {
    constructor(props) {
        super(props);
        this.captcha = null;
        this.state = {
            loadedPost: null,
            fingerprint: '',
            responseToken: '',
            votingButton: 'Jetzt per SMS abstimmen!',
            votingReady: true,
            captchaContainerVisibility: false
        };
    }

    componentDidMount() {
        const options = {};
        let fingerprint;
        Fingerprint2.getPromise(options).then((components) => {
            const values = components.map(({value}) => value).join("");
            fingerprint = Fingerprint2.x64hash128(values, 31);
            this.setState({fingerprint});
        });
    }

    onVoteHandler = () => {
        this.fingerprintVote();
    };

    fingerprintVote = () => {
        const {fingerprint} = this.state;
        const {id} = this.props.match.params;
        axios.patch(`/projects/${id}/vote`,
            {
                fingerprint: fingerprint
            },
            {wafProtected: true})
            .then(response => {
                if (response.status === 200) {
                    this.setState({captchaContainerVisibility: false, votingReady: false}, () => {
                        if (response !== undefined) {
                            this.setState({
                                votingButton: 'Vielen Dank für Ihre Stimme!'
                            });
                            this.props.history.push('/stimme-bestaetigt');
                        } else {
                            this.setState({
                                votingButton: 'Sie haben bereits abgestimmt!'
                            });
                            // throw new Error('Sie haben bereits abgestimmt!');
                            this.handleUnsuccessfulFingerprintVote(response);
                        }
                    })
                } else {
                    this.handleUnsuccessfulFingerprintVote(response);
                }
            })
            .catch(err => {
                if (err.response.status === 405) {
                    //hide sms request button
                    this.setState({captchaContainerVisibility: true}, () => {
                        // only then display captcha
                        this.displayCaptchaPuzzle();
                    })
                } else {
                    this.handleUnsuccessfulFingerprintVote(err);
                }
            })
    }

    displayCaptchaPuzzle = () => {
        this.setState({captchaContainerVisibility: true});
    }

    onCaptchaVerified = () => {
        //Called on Successful Captcha
        //Hide captcha success message to remove confusion in voting process
        // Display voting button as animation progress and redirect are shown within it
        this.setState({
            captchaContainerVisibility: false
        }, () => {
            // only then display captcha
            this.onVoteHandler();
        })

    }

    handleUnsuccessfulFingerprintVote = (responseOrError) => {
        // showing the error is covered by the axios interceptor, so this method is currently superfluous and only logs
        // the error.
        console.error(responseOrError)
    }

    render() {
        return (
            <Col xs="12" sm="6" lg="12">
                <WafCaptcha show={this.state.captchaContainerVisibility} onVerified={this.onCaptchaVerified}/>
                {!this.state.captchaContainerVisibility && (
                    <Button
                        className={this.state.votingReady ? 'anl_fp--vote' : null}
                        block
                        disabled={!this.state.votingReady}
                        color="primary"
                        onClick={this.onVoteHandler}
                    >
                        {this.state.votingButton}
                    </Button>
                )}
            </Col>
        );
    }
}


export default FingerprintVote;
